import React, { useState, useEffect } from 'react'
import "./messages.css"
import { DataGrid} from '@mui/x-data-grid';
import { getAllMessages } from '../../apis/Messages';
import {getRegisteredNumbers} from '../../apis/RegisterdNumbers'
import { useNavigate } from 'react-router-dom';

import { setContact } from '../../redux-store/StatusSlice';
import { useDispatch } from 'react-redux';
import { Toaster, toast } from 'sonner'
import dateFormat from 'dateformat';
import Tooltip from '@mui/material/Tooltip';
import Loading from '../ReUsedComponents/loadingScreen/Loading';


const Messages = () => {

    const dispatch = useDispatch()

    const [incomingMessages, setIncomingMessages] = useState([])
    const [outgoingMessages, setOutgoingMessages] = useState([])
  
    const [pageSize, setPageSize] = useState(25);
    const [pageNo, setPageNo] = useState(1);

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)

    const [whatsappNumber, setWhatsappNumber] = useState('')
    const [inboundUserNumber, setInboundUserNumber] = useState('')
    const [outboundUserNumber, setOutboundUserNumber] = useState('')
    const [registeredWabaNumbers, setRegisteredWabaNumbers] = useState([])

    let navigate = useNavigate()
        
    useEffect(() =>{ 
        getAllMessages(dispatch, pageSize, pageNo, whatsappNumber ,"INBOUND", inboundUserNumber).then(resp => {
            if(resp.status === 200){
                setIncomingMessages(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

           getAllMessages(dispatch, pageSize, pageNo, whatsappNumber, "OUTBOUND", outboundUserNumber).then(resp => {
            if(resp.status === 200){
                setOutgoingMessages(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

           getRegisteredNumbers(dispatch).then(resp => {
            if(resp.status === 200){
              setRegisteredWabaNumbers(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

    }, [dispatch,pageNo, pageSize, whatsappNumber, inboundUserNumber, outboundUserNumber, step1, step2 ])

    const getMessagesByContact = (contactId) => {
        dispatch(setContact(contactId))
        navigate('/app/chats')
    }

    const columns = [
        {field: 'whatsappNumber', headerName: 'WhatsApp Number',flex:1, minWidth: 100, headerClassName: 'custom-table-header first-header',sortable: false,},
        {field: 'type', headerName: 'Type',width: 80, headerClassName: 'custom-table-header first-header',sortable: false,},
        {field: 'text', headerName: 'Message',width: 150, headerClassName: 'custom-table-header first-header',sortable: false,},
        {field: 'mediaUrl', headerName: 'Media Url', width: 100, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (props) => props?.row?.mediaUrl? <a href='https://www.google.com' target='_blank' rel="noreferrer">Media Url</a> : ''},
        {field: 'name', headerName: 'Contact',flex:1, minWidth: 70, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (props) => <Tooltip title={`Click to view messages`}><span onClick={() => getMessagesByContact(props.row.contact)} id='contact-name'>{props.row?.contact?.name}</span></Tooltip>},
        {field: 'phoneNumber', headerName: 'Phone Number',width: 140, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (props) => props.row?.contact?.phoneNumber},
        // {field: 'direction', headerName: 'Direction',width: 120, headerClassName: 'custom-table-header first-header',sortable: false,
        // renderCell: (props) => <span id={`direction-${props?.row?.direction}`}>{props?.row?.direction}</span>},
        {field: 'status', headerName: 'Status',width: 120, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (props) => <span id={`status-${props?.row?.status}`}>{props?.row?.status}</span>},
        { field: 'createdAt', headerName: 'Created At',flex:1, minWidth: 70, headerClassName: 'custom-table-header',sortable: false,
        renderCell: (props)=> <span>{dateFormat(props.row?.createdAt, "dd/mm/yyyy HH:MM")}</span>},
    ];

   
    return (
        <div class='table-page'>

            <div className='table-actions-sections'>
                <div className='table-page-title-section'>
                    <p className='table-page-title'>Manage Massages </p>
                </div>
            </div>
            <div>

            <div style={{display:"flex"}}>
                <p className={`step step1-${step1}`} onClick={() => {setStep1(true); setStep2(false); document.getElementById('myInput').value = ''; setOutboundUserNumber('')}}>Incoming Messages</p>
                <p className={`step step1-${step2}`} onClick={() => {setStep2(true); setStep1(false); document.getElementById('myInput').value = ''; setInboundUserNumber('')}}>Outgoing Messages</p>    
            </div>

            <div className='table-toolbar' id='messages-table-toolbar'>
                    <div className='filter-messages-section'>
                        <label className='filter-label'>Filter by business number: </label>
                        <select type='text' className='filter-select-field' value={whatsappNumber} onChange={(e) => { setWhatsappNumber(e.target.value) }}>
                        <option id='option' value="" selected>All</option>
                        <option id='option' value={`whatsappNumber=+254110090747`}>Sandbox Number</option>
                        {registeredWabaNumbers.map((item, id) =>
                            <option id='option' key={id} style={{ fontSize: '14px' }} value={`whatsappNumber=${item.number}`}>{item.number}</option>
                        )}
                        </select>
                    </div>

                   {step1?
                   <div className='filter-messages-section'>
                     <div className='search-table-input'>
                       <label className='filter-label'>Filter by user number: </label>
                       <input type='text' className="s-table-input" placeholder='Search....' onChange={(e) => setInboundUserNumber(e.target.value === '' ? '' : `userNumber=${e.target.value}`)} id="myInput"/>
                   </div>
                </div> :
               
                 <div className='filter-messages-section'>
                    <div className='search-table-input'>
                      <label className='filter-label'>Filter by user number: </label>
                      <input type='text' className="s-table-input" placeholder='Search....'  onChange={(e) => setOutboundUserNumber(e.target.value === '' ? '' : `userNumber=${e.target.value}`)} id="myInput" />
                    </div>
                </div>
                    }     
             </div>

            
            {step1?
            <div>
           {incomingMessages.length === 0? <Loading /> : 
            <DataGrid rowHeight={40} rows={incomingMessages?.data} columns={columns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                   
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={incomingMessages?.totalElements}
                    paginationMode="server"
                    pagination

                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',}} 
                    autoHeight/>}
            </div>
            :

            <div>
            {outgoingMessages.length === 0? <Loading /> : 
            <DataGrid rowHeight={40} rows={outgoingMessages.data} columns={columns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage)}
                    rowsPerPageOptions={[50, 100]}
                    rowCount={outgoingMessages?.totalElements}
                    paginationMode="server"
                    pagination

                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',}} 
                    autoHeight/>}
            </div> }
          
            </div>

            <Toaster position="top-right" expand={false} richColors/>

        </div>
    )
}

export default Messages