import './confirmemail.css'


const ConfirmEmail = ({ handleCloseMessage, handleAction }) => {

    return (

        <div className='confirm-modal-active'>
            <div className="confirm-modal" >

                <div className='confirm-messages-div'>
                <span className="action-title">Email Confirmation</span>
                <p className='confirm-message'>Email confirmed successfuly. Log in to proceed</p>
                </div>
               
                <div className='a-buttons'>
                    <a href='/auth/login' className="dismiss-button">Login</a>
                </div>

            </div>
        </div>

    )
}

export default ConfirmEmail