
import React from 'react'
import './pagesidebar.css'
import { NavLink } from 'react-router-dom'


const PageSideBar = ({ idName }) => {



  // const togleMenu = () => {
  //   var x = document.getElementById("list-group-items");
  //   var y = document.getElementById("arrow");
  //   if (x.style.display === "block") {
  //     x.style.display = "none";
  //     y.style.transform = "rotate(0deg)";
  //   } else {
  //     x.style.display = "block";
  //     y.style.transform = "rotate(90deg)";
  //   }
  // }

  return (


    <div className='pagesidebar' id={idName}>

    <div href='/app/dashboard' className='logo-link'>
      <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="company-logo" className="sidebar-logo" />
      <i className="pi pi-times" id="sidebar-hide-btn"/>
    </div>

    <div className='pagesidebar-menu-section'>
      <div className='menu-list'>
       

       <NavLink to='/api/get-started' className='api-heading-link'>Getting Started</NavLink>
       {/* <NavLink to='/api/authentication' className='api-heading-link'>Authentication</NavLink> */}
       <NavLink to='/api/status-codes' className='api-heading-link'>API Status Codes</NavLink>
       <NavLink to='/api/text-message' className='api-heading-link'>Text message</NavLink>
       {/* <NavLink to='/api/whatsapp-create-message' className='api-heading-link'> Create Templates Message</NavLink>
       <NavLink to='/api/whatsapp-template-message' className='api-heading-link'>Templates Message</NavLink> 
       <NavLink to='/api/whatsapp-contact-message' className='api-heading-link'> Contacts Message</NavLink> */}
       <NavLink to='/api/image-message' className='api-heading-link'>Image Message</NavLink>
       <NavLink to='/api/video-message' className='api-heading-link'>Video Message</NavLink>
       <NavLink to='/api/audio-message' className='api-heading-link'>Audio Message</NavLink>
       <NavLink to='/api/buttons-message' className='api-heading-link'>Interactive Buttons Message</NavLink>
       {/* <NavLink to='/api/whatsapp-location-message' className='api-heading-link'>Location Message</NavLink>
      <NavLink to='/api/whatsapp-sticker-message' className='api-heading-link'>Sticker Message</NavLink> */}

      </div>

    </div>

  </div>

  )

}

export default PageSideBar