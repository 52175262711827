import React, { useState, useEffect } from 'react';
import './homepage.css'
import { useSelector  } from 'react-redux';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { v4 as uuidv4 } from 'uuid';
import { Toaster, toast } from 'sonner'

const Homepage = ({ toggleSideBar }) => {

    const selector = useSelector((state) => state.auth)
    const userDetails = JSON.parse(selector?.userInfo)
    const userApiKey = JSON.parse(selector?.apiKey)

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)

    const [uuid, setUuid] = useState('');

    const [isHidden, setIsHidden] = useState(true);

    useEffect(() => {
      const generatedUuid = uuidv4();
      setUuid(generatedUuid);
    }, []);

    const requestData = {
      message: 'This is a test message.',
      messageId: uuid,
      to: "{{PHONE_NUMBER}}",
      from: selector?.sandBoxNumber
    };

    const responseData = {
      timestamp: "2023-08-06T01:27:56.825898971",
      data: {
        messageId: uuid,
        waId: "wamid.HBgMMjU0NzE3NzQ2NTY1FQIAEnRgSRjJGRkMzNkQ0QUVENTIxQ0NBAA==",
        status: "SENT",
        statusDesc: "Message sent successfully"
      },
      status: "success",
      message: "",
      errors: null
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(userDetails?.client?.apiKey)
        .then(() => {
          toast.success('API Key copied!')
        })
        .catch(err => {
          toast.error('Invalid username or password!')
        });
    };

   
  
  return (
    <div className="welcome-page">
 <Toaster position="top-right" expand={false} richColors/>
      <p id="greeting-message">Hi 👋, {userDetails?.firstName + " " + userDetails?.lastName}</p>
      <div className="instruction-section">
      <p id="instruction-section-header">Get started with <span id="api-name">WhatsApp API</span> </p>

      <div style={{display:"flex"}}>
        <p className={`step step1-${step1}`} onClick={() => {setStep1(true); setStep2(false)}}>1. Activate sandbox to start testing</p>
        <p className={`step step1-${step2}`} onClick={() => {setStep1(false); setStep2(true)}}>2. Send your first message</p>
      </div>

      <div className="steps-section">
        {step1?
         <ol id="step-instructions">
         <li>Send the word <b>"join {userDetails?.client?.sandboxJoinCode}"</b> to the whatsapp sandbox number &nbsp; 
          <b>{selector?.sandBoxNumber}</b></li>
           <p><b>OR</b></p>
          <p>Click   
          <a href={`https://wa.me/254110090747?text=join%20${userDetails?.client?.sandboxJoinCode}`} target='_blank'
          id='join-code-link'  rel="noreferrer"> Send Join Code</a>
          </p>
         </ol>
          :
          <div>
           <p id='intro-message'>Send Whatsapp Text message through your application by making a <b>HTTP POST</b> request to the following endpoint: 
          <span id='endpoint-value'> https://gateway.lipachat.com/api/v1/whatsapp/text</span></p> 

          <p id='intro-message'>Visit our documentation for more info:
          <a href="https://documentation-lipachat.netlify.app/api/get-started" target='_blank' id='join-code-link' rel="noreferrer"> Documentation</a></p> 

      
          <div className='api-key-section'>
             <p>API Key: </p> 
              {isHidden? <p id='api-key-value-starts'><span id='star-key'>{'******'.repeat(userApiKey.length / 5)}</span><span onClick={()=>setIsHidden(false)} class="material-symbols-outlined">visibility</span></p>
              :<p id='api-key-value'>{userApiKey}<span onClick={()=>setIsHidden(true)} class="material-symbols-outlined">visibility_off</span></p>
              }
             <span id='copy-btn' class="material-symbols-outlined" onClick={copyToClipboard}>content_copy</span>
          </div>


          <div id="step-instructions" className="send-first-message-instr">
           <div className="request-payload-section">
            <p id="payload-title">Request Sample</p>
            <SyntaxHighlighter language="json" style={vscDarkPlus}>
            {JSON.stringify(requestData, null, 2)}
            </SyntaxHighlighter>
           </div>

           <div className="response-payload-section">
            <p id="payload-title">Response Sample</p>
            <SyntaxHighlighter language="json" style={vscDarkPlus}>
              {JSON.stringify(responseData, null, 2)} 
              </SyntaxHighlighter>
            </div>
            </div>

          </div>
        }
        
      </div>

      </div>

    </div>
  )
}

export default Homepage