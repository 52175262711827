import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux-store/ActionSlice";

export const getAllBilling = async (dispatch, pageSize, pageNo, startDate, endDate, wabaPhoneNumber) => {
    dispatch(actionStart())
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL+`/conversation?per_page=${pageSize}&page=${pageNo}&startDate=${startDate}&endDate=${endDate}&wabaPhoneNumber=${wabaPhoneNumber}`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}


export const getStatements = async (dispatch, pageSize, pageNo) => {
    dispatch(actionStart())
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL+`/billing/statement?per_page=${pageSize}&page=${pageNo}`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}