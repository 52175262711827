import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux-store/ActionSlice";

export const getRegisteredNumbers = async (dispatch) => {
    dispatch(actionStart())
   
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL+`/phonenumber`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}


export const registeredNumber = async (dispatch, phoneNumber) => {
    dispatch(actionStart())
   
    try {
        const resp = await axios.post(process.env.REACT_APP_BASE_URL+'/phone/register', phoneNumber, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}