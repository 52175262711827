
import React from 'react'
import { NavLink } from 'react-router-dom';
import './sidebar.css'
import { useSelector } from 'react-redux';

const SideBar = ({ idName }) => {

  const selector = useSelector((state) => state.auth)
  const userDetails = JSON.parse(selector?.userInfo)
   

  return (
    <div className='sidebar' id={idName}>

    <div href='/app/dashboard' className='logo-link'>
      <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="company-logo" className="sidebar-logo" />
      <i className="pi pi-times" id="sidebar-hide-btn"/><br/>
    </div>

     <div>
        <p className='company-name-sec'><span class="material-symbols-outlined" id="h-icon">apartment</span> {userDetails?.client?.name} </p>
      </div>

      
    <div className='sidebar-menu-section'>
      <div className='menu-list'>
        <NavLink to='/app/home' className='heading-link'>
        <span class="material-symbols-outlined" id="h-icon">home</span>Home
        </NavLink>

        <NavLink to='/app/whatsapp-manager' className='heading-link'>
        <span class="material-symbols-outlined" id="h-icon">sensor_occupied</span>Go Live
        </NavLink>

        <NavLink to='/app/messages' className='heading-link'>
        <span class="material-symbols-outlined" id="h-icon">sms</span>Messages
        </NavLink>

        <NavLink to='/app/chats' className='heading-link'>
        <span class="material-symbols-outlined" id="h-icon">forum</span>Chats
        </NavLink>

        <NavLink to='/app/web-hooks' className='heading-link'>
        <span class="material-symbols-outlined" id="h-icon">webhook</span>Callbacks
        </NavLink>

        <NavLink to='/app/settings' className='heading-link'>
        <span class="material-symbols-outlined" id="h-icon">settings</span>Settings
        </NavLink>

        <NavLink to='/app/billing' className='heading-link'>
        <span class="material-symbols-outlined" id="h-icon">credit_card</span>Billing
        </NavLink>

        <a  href="https://documentation-lipachat.netlify.app/api/get-started" target="_blank" rel="noreferrer" className='heading-link'>
        <span class="material-symbols-outlined" id="h-icon">article</span>API Documentation
        </a>

      </div>


        {/* user profile section */}
      {/* <div className='sidebar-profile-section'>
          
          <div className='sidebar-profile-links' id='profile-menu-section'>
            <NavLink to='/app/profile' id='profile-link'>
            <span class="material-symbols-outlined">person</span>Profile</NavLink>
            <a href="/auth/login" id='profile-link' className='logout-btn' onClick={logoutUser}>
            <span class="material-symbols-outlined">logout</span>Logout</a>
          </div>

          <p className='sidebar-profile-btn' onClick={() => togleProfileMenu()}>
            <p id='sidebar-profile-details'><img src={process.env.PUBLIC_URL + "/images/user.png"} alt="company-logo" style={{width: '42px'}}/>  
             <p><span id='profile-name'>Lipachat Admin</span><br/> <span id='profile-email'>admi@lipachat.com</span></p> </p>
          </p>

      </div> */}


    </div>

  </div>

  )

}

export default SideBar