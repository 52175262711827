import { createSlice } from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: {
        userInfo: null,
        userToken: null,
        sandBoxNumber: null,
        loggedIn: false,
        apiKey: null,
        callbackUrl: null,
        statusCallbackUrl: null,
        accountBalance: null
    },
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
        },
        setUserToken: (state, action) => {
            state.userToken = action.payload
        },
        setLoginState: (state, action) => {
            state.loggedIn = action.payload
        },
        setSandBoxNumber: (state, action) => {
            state.sandBoxNumber = action.payload
        },
        setApiKey: (state, action) => {
            state.apiKey = action.payload
        },
        setCallbackUrl: (state, action) => {
            state.callbackUrl = action.payload
        },
        setStatusCallbackUrl: (state, action) => {
            state.statusCallbackUrl = action.payload
        },
        setAccountBalance: (state, action) => {
            state.accountBalance = action.payload
        },
        logoutUser: (state) => {
            state.userInfo = null
            state.token = null
            state.loggedIn = false
            state.sandBoxNumber = null
            state.apiKey = null
            state.callbackUrl = null
            state.statusCallbackUrl = null
            state.accountBalance = null
        },
    },

})

export const { setUserInfo, setUserToken, setLoginState, setSandBoxNumber, logoutUser, setApiKey, setCallbackUrl, setStatusCallbackUrl, setAccountBalance  } = AuthSlice.actions
export default AuthSlice.reducer