
import './loading.css'

const Loading = () => {

    return (
    <div className="loading-dual-ring"></div>
           
    )
}

export default Loading