import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux-store/ActionSlice";

export const getWebhooks = async (dispatch, pageSize, pageNo, number) => {
    dispatch(actionStart())
   
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL+`/webhook/logs?per_page=${pageSize}&page=${pageNo}&${number}`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}