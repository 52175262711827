import React, { useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom';
import './login.css'

import { loginUser } from '../../apis/Authentication';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo, setUserToken, setLoginState, setSandBoxNumber, setApiKey, setCallbackUrl, setStatusCallbackUrl } from '../../redux-store/AuthSlice';
import { Toaster, toast } from 'sonner'

const Login = () => {

    const dispatch = useDispatch()
    const loginPending = useSelector((state) => state.action)
    let navigate = useNavigate()

    const [user, setUser] = useState()
    const [formErrors, setFormErrors] = useState()

    // Handling setting user info
    const handleOnChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const loading = () => {
        return <div class="lds-dual-ring"></div>
    }

    // Handling form validation
    const validate = () => {
        let errors = {}

        if (!user?.username) {
            errors['username'] = 'Username is required!'
        }
        if (!user?.password) {
            errors['password'] = 'Password is required!'
        }
        setFormErrors(errors)
        return errors
    };


    // Handle: User login
    const handleLoginUser = () => {
        if (Object.keys(validate(user)).length === 0) {
            loginUser(dispatch, user).then((resp) => {
                if (resp?.status === 200) {
                    localStorage.setItem("token", resp?.data?.data?.token)
                    localStorage.setItem("userinfo", JSON.stringify(resp?.data?.data?.user))
                    localStorage.setItem("loginState", true)
                    localStorage.setItem("apikey", JSON.stringify(resp?.data?.data?.user?.client?.apiKey))
                    localStorage.setItem("callbackUrl", JSON.stringify(resp?.data?.data?.user?.client?.callbackUrl))
                    localStorage.setItem("statusCallbackUrl", JSON.stringify(resp?.data?.data?.user?.client?.statusCallbackUrl))
                    localStorage.setItem("sandBoxNumber", resp?.data?.data?.config?.sandboxNumber)
                    dispatch(setUserToken(localStorage.getItem("token")))
                    dispatch(setUserInfo(localStorage.getItem("userinfo")))
                    dispatch(setLoginState(localStorage.getItem("loginState")))
                    dispatch(setSandBoxNumber(localStorage.getItem("sandBoxNumber")))
                    dispatch(setApiKey(localStorage.getItem("apikey")))
                    dispatch(setCallbackUrl(localStorage.getItem("callbackUrl")))
                    dispatch(setStatusCallbackUrl(localStorage.getItem("statusCallbackUrl")))
                    navigate('/app/home')
                }
                else if (resp?.response?.data?.message?.includes("User does not exists") || resp?.response?.status === 401) {
                    toast.error('Invalid username or password!')
                    console.log(resp)
                }

                else {
                    toast.error('Something went wrong.Try again!')
                    console.log(resp)
                }

            })
        }
    }


    return (

        <div>
           <Toaster position="top-right" expand={false} richColors/>
         
           <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="welcome-logo" className="company-login" />

           <div className='login-page'>

              {/* <div className='login-left-side'>
                <img src={process.env.PUBLIC_URL + "/images/background-login.png"} alt="welcome-logo" className="login-background" />
            </div> */}


           <div className='login-left-side'>
                <div className='left-content'>
                    <p className='left-content-header'>Do More on WhatsApp with Lipachat's Whatsapp API</p>
                    <ul id='content-list'>
                        <li id='content-item'>Build bots to automate customer service and marketing</li>
                        <li id='content-item'>Send broadcasts to all your contacts in minutes</li>
                        <li id='content-item'>Manage your contacts</li>
                        <li id='content-item'>Save money by saving time</li>
                    </ul>
                    <a id='website-link' href='https://app.lipachat.com/auth/signup' rel="noreferrer">Sign up</a>
                </div>
            </div>


            <div className='login-form-section'>
                <form className='login-form'>
                    <p className='login-welcome-header'>Please Sign In</p>
                    {/* <p className="login-title2">Use Your Credentials To Login</p> */}

                    <div className='input-group'>
                        <label className='login-label'>Email/Username</label>
                        <input type="email" name='username' className='login-input' onChange={(e) => handleOnChange(e)} />
                        <span id='form-error'>{formErrors?.username}</span>
                    </div>

                    <div className='input-group'>
                        <label className='login-label'>Password</label>
                        <input type="password" name='password' className='login-input' onChange={(e) => handleOnChange(e)} />
                        <span id='form-error'>{formErrors?.password}</span>
                    </div>
                    <NavLink to="/auth/forgot_pass" className="forgot-link">Forgot Your Password?</NavLink>

                    <div>
                        {!loginPending.pending ?
                            <button type='button' className="login-button" onClick={handleLoginUser}>Login</button> :
                            <button type='button' className="login-disable" disabled="disabled">{loading()}</button>
                        }
                    </div>

                    <div className='login-actions'>
                        <p className="signup-section">New on our platform? <NavLink to="/auth/signup" className="signup-link">Sign Up</NavLink></p>
                    </div>
                </form>
            </div>

            </div>

        </div>
    );
}

export default Login