import React, { useState, useEffect } from 'react';
import '../pages/docstyle.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { v4 as uuidv4 } from 'uuid';

const SendButtons = () => {


    const [uuid, setUuid] = useState('');

    const [response200, setResponse200] = useState(true)
    const [response400, setResponse400] = useState(false)
    const [response401, setResponse401] = useState(false)

    useEffect(() => {
      const generatedUuid = uuidv4();
      setUuid(generatedUuid);
    }, []);
    
    const requestData = {
      text: "Hello, are you interested in learning more about our products?",
      buttons: [ // A maximum of three buttons
              {
              "id": "1", "title": "YES"
              }, {
              "title": "NO" }
              ],
      messageId: uuid,
      to: "{{PHONE_NUMBER}}",
      from: "{{SANDBOX_NUMBER}}",
    };
  
      const responseData200 = {
        timestamp: "2023-08-06T01:27:56.825898971",
        data: {
          messageId: uuid,
          waId: "wamid.HBgMMjU0NzE3NzQ2NTY1FQIAEnRgSRjJGRkMzNkQ0QUVENTIxQ0NBAA==",
          status: "SENT",
          statusDesc: "Media sent successfully"
        },
        status: "success",
        message: "",
        errors: null
      };

      const responseData401 = {
        timestamp: "2023-08-06T20:49:48.455464058", "data": null,
        status: "success",
        message: "Invalid credentials",
        errors: null 
      }
     
      const responseData400 = {
        timestamp: "2023-08-06T17:50:35.701+00:00",
        status: 400,
        error: "Bad Request",
        path: "/api/v1/whatsapp/message/text"
      };

  return (
    <div className="request-page">

      <div className='request-explanation-section'>

        <div style={{marginBottom: '20px'}}>
          <p id="instruction-section-header">Send Whatsapp Interactive Button Message</p>
          <p id='intro-message'>Send Whatsapp Button message through your application by making a <b>HTTP POST</b> request to the following endpoint:<br/>
          <span id='endpoint-value'>https://gateway.lipachat.com/api/v1/whatsapp/interactive/buttons</span></p> 
       </div>

      <div>
          <p id="instruction-section-header">Request Parameters</p>
          <p id='intro-message'>The body of the request should contain the following fields in json format:</p> 

          <table class="table">

            <thead><tr><th>Request Body</th></tr></thead>
       
            <tbody>
                  <tr>
                      <td>
                      <strong>text</strong> <code>String Url</code> <code>Required</code> <br/>
                        <p id="parameter-desc">Content of the message body.</p>
                      </td>
                  </tr>

                  <tr>
                      <td>
                      <strong>buttons</strong> <code>Array of objects </code> <code>Required</code> <br/>
                        <p id="parameter-desc">An array of buttons sent in a message. A maximum of three buttons.</p>
                      </td>
                  </tr>



                  <tr>
                      <td>
                      <strong>messageId</strong> <code>String</code> <code>Required</code> <br/>
                      <p id="parameter-desc">A unique identifier for the message. This will be used
                       to track the message status and for deduplication purposes.</p>
                      </td>
                  </tr>

                  <tr>
                      <td>
                      <strong>to</strong> <code>String</code> <code>Required</code> <br/>
                      <p id="parameter-desc">Comma Separated phone numbers</p>
                      </td>
                  </tr>

                  <tr>
                      <td>
                      <strong>from</strong> <code>String</code> <code>Required</code> <br/>
                      <p id="parameter-desc">Sandbox number +254110090747 or a valid WABA phone number.</p>
                      </td>
                  </tr>
   
              </tbody>
        </table>
          
      </div>

      </div>

      <div className="request-sample-section">
           <div>
            <p id="code-block-title">Request Sample</p>
            <SyntaxHighlighter className="custom-code-block-tempalate" language="json" style={vscDarkPlus}>
            {JSON.stringify(requestData, null, 2)}
            </SyntaxHighlighter>
           </div>


          <div style={{marginTop: '20px'}}>
           <p id="code-block-title">Response Sample</p>
            <div className='response-codes'>
              <button className={`response-btn success-${response200}`}  onClick={() => {setResponse200(true); setResponse400(false); setResponse401(false)}}>200</button>
              <button className={`response-btn error-${response400}`} onClick={() => {setResponse200(false); setResponse400(true); setResponse401(false)}}>400</button>
              <button className={`response-btn error-${response401}`} onClick={() => {setResponse200(false); setResponse400(false); setResponse401(true)}}>401</button>
            </div>

            {response200?
              <SyntaxHighlighter className="custom-code-block-tempalate" language="json" style={vscDarkPlus}>
                  {JSON.stringify(responseData200, null, 6)} 
              </SyntaxHighlighter>
              :response400?
              <SyntaxHighlighter className="custom-code-block-tempalate" language="json" style={vscDarkPlus}>
                  {JSON.stringify(responseData400, null, 6)} 
              </SyntaxHighlighter>
              :response401?
              <SyntaxHighlighter className="custom-code-block-tempalate" language="json" style={vscDarkPlus}>
                  {JSON.stringify(responseData401, null, 6)} 
              </SyntaxHighlighter>:''
          }

          </div>
      </div>

      </div>

  
  )
}

export default SendButtons