import React, { useState, useEffect } from 'react'
import "./chats.css"

import { getMessagesByContactId } from '../../apis/Messages';
// import { setContact } from '../../redux-store/StatusSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster, toast } from 'sonner'
import dateFormat from 'dateformat';
import {getRegisteredNumbers} from '../../apis/RegisterdNumbers'


const Chats = () => {

  const dispatch = useDispatch()
  const contactinfo = useSelector((state) => state.status)

  const [allMessages, setAllMessages] = useState([])

  const [whatsappNumber, setWhatsappNumber] = useState('')
  const [registeredWabaNumbers, setRegisteredWabaNumbers] = useState([])

  useEffect(() => { 
    if(contactinfo.contact){
      getMessagesByContactId(dispatch, 100, 1, contactinfo.contact.id).then(resp => {
        if(resp.status === 200){
          setAllMessages(resp?.data?.data)
          
        }else{
          toast.error('Something went wrong. Re-load page!')
        }
       })
    }

    getRegisteredNumbers(dispatch).then(resp => {
      if(resp.status === 200){
        setRegisteredWabaNumbers(resp.data?.data)
      }else{
        toast.error('Something went wrong. Re-load page!')
      }
     })
}, [dispatch, contactinfo.contact])


    return (
        <div className='chat-conversation-section'>

          
          {!contactinfo.contact?
          
          <div className='empty-conversation-section'>
            <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="company-logo" style={{width: '200px'}} />
            <p>*****COMING SOON*****</p>
          </div>
          
          :
        <div className='chats-conversation-section'> 
          <div className='contact-profile-section'>
            <p><img src={process.env.PUBLIC_URL + "/images/user.png"} alt="company-logo"/>{contactinfo.contact.name}</p>

                <div className='filter-messages-section'>
                        <label className='filter-label'>Filter by business number: </label>
                        <select type='text' className='filter-select-field' value={whatsappNumber} onChange={(e) => { setWhatsappNumber(e.target.value) }}>
                        <option id='option' value="" selected>All</option>
                        <option id='option' value="254110090747">Sandbox Number</option>
                        {registeredWabaNumbers.map((item, id) =>
                            <option id='option' key={id} style={{ fontSize: '14px' }} value={`whatsappNumber=${item.number}`}>{item.number}</option>
                        )}
                        </select>
                    </div>
            </div>
          <div className='conversation-section'>
            <div className='messages-section'>
             {allMessages?.data?.reverse().map((message, index) =>(
               message.direction === "INBOUND"?

               <div className='outgoing-message-sec' key={index}>
               <div className='outgoing-message-div'>
                 <p className='outgoing-message-content'>{message.text}</p>
                 <p className="message-timestamp">{dateFormat(message?.createdAt, "dd/mm/yyyy HH:MM")}</p>
               </div>
               <img src={process.env.PUBLIC_URL + "/images/user.png"} alt="company-logo" style={{width: '40px'}}/>  
           </div>:

              <div className='incoming-message-sec' key={index}>
                  <img src={process.env.PUBLIC_URL + "/images/user.png"} alt="company-logo" style={{width: '40px'}}/>  
                    <div className='incoming-message-div'>
                      <p className='incoming-message-content'>{message.text}</p>
                      <p className="message-timestamp incoming-timestamp">{dateFormat(message?.createdAt, "dd/mm/yyyy HH:MM")}</p>
                    </div>
                </div>
      
              
             ))}
            </div>
          </div>
        </div>  }

        <Toaster position="top-right" expand={false} richColors/>
        </div>
  
    )
}

export default Chats