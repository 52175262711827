import { createSlice } from "@reduxjs/toolkit";

export const StatusSlice = createSlice({
    name: 'status',
    initialState: {
        filterQuery: '',
        contact: ''
    },
    reducers: {

        setFilterQuery: (state, action) => {
            state.filterQuery = action.payload
        },

        setContact: (state, action) => {
            state.contact = action.payload
        },
    },

})

export const { setFilterQuery, setContact } = StatusSlice.actions
export default StatusSlice.reducer