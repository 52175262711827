import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux-store/ActionSlice";

export const submitMpesaRequest = async (dispatch, details) => {
    dispatch(actionStart())
  
    try {
        const resp = await axios.post(process.env.REACT_APP_BASE_URL + "/topup/stk", details, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}


export const getAccountBalance = async (dispatch) => {
    dispatch(actionStart())
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL+"/billing/balance", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}

export const submitOrderRequest = async (dispatch, pesapalDetails) => {
    dispatch(actionStart())
  
    try {
        const resp = await axios.post(process.env.REACT_APP_BASE_URL+"/pesapal/submitOrder", pesapalDetails, {
            headers: {
                'Content-Type': 'application/json', 
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}

export const getOrderDetails = async (dispatch) => {
    dispatch(actionStart())
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL+ "/pesapal/order-response", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}

export const convertToUsd = async (dispatch, amount) => {
    dispatch(actionStart())
  
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL + "/billing/convert?currency=KES&amount="+amount, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}
