import axios from "axios";
import { actionStart, actionSuccess, actionFailed } from "../redux-store/ActionSlice";

export const getAllMessages = async (dispatch, pageSize, pageNo, number, direction, userNumber) => {
    dispatch(actionStart())
   
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL+`/message?per_page=${pageSize}&page=${pageNo}&${number}&direction=${direction}&${userNumber}`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}


export const getMessagesByContactId = async (dispatch, pageSize, pageNo, contactId) => {
    dispatch(actionStart())
   
    try {
        const resp = await axios.get(process.env.REACT_APP_BASE_URL+`/message/${contactId}?per_page=${pageSize}&page=${pageNo}`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        dispatch(actionSuccess())
        return resp
    } catch (err) {
        dispatch(actionFailed())
        return err
    }
}