import React, { useState, useEffect } from 'react'
import './profile.css'

import Input from '../ReUsedComponents/inputField/Input'
import SubmitButton from '../ReUsedComponents/submitButton/SubmitButton';

import { updateUser, getUserDetails } from '../../apis/UserAPI';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster, toast } from 'sonner'

import dateFormat from 'dateformat';

const Profile = () => {

    const dispatch = useDispatch()
    // const userInfo = useSelector((state) => JSON.parse(state.auth.userInfo))
    const action = useSelector((state) => state.action)


    const [user, setUser] = useState();
    const [formErrors, setFormErrors] = useState()
    const [update, setUpdate] = useState(false);

    const handleOnChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }


    //////////GETTING ALL USERS/ROLES//////////
    useEffect(() => {
        getUserDetails(dispatch).then(resp => {
            if (resp?.status === 200) { setUser(resp?.data?.data) }
            else { toast.error("Unabled to fetch roles!", { theme: "colored" }) }
        })
    }, [dispatch])

    //////////VALIDATING FORM//////////
    const validate = () => {
        let errors = {}
        if (!user?.firstName) {
            errors['firstName'] = 'Field is required!'
        }
        if (!user?.lastName) {
            errors['lastName'] = 'Field is required!'
        }
        if (!user?.username) {
            errors['username'] = 'Field is required!'
        }
        if (!user?.client?.name) {
            errors['company'] = 'Field is required!'
        }

        setFormErrors(errors)
        return errors
    };

    //////////UPDATE USER//////////
    const handleUpdateUser = () => {
        if (Object.keys(validate()).length === 0) {
            updateUser(user, dispatch).then(resp => {
                if (resp?.status === 200) {
                    toast.success("Updated successful!", { theme: "colored" })
                    window.location.reload()
                }
                else { toast.error("Unable to update!", { theme: "colored" }) }
            })
        }
    }


    const handleCloseModal = () => {
        document.getElementById("form-modal").reset();
        setUpdate(false)
    }

    return (

        <div className='user-profile-page'>
           
            <div className='page-header-section'>
               <p className='profile-title'>Manage Your Profile</p>
            </div>

            <div className='main-profile-section'>
                <div className='user-info-section'>
                    <div className='page-header-section' style={{display:'flex', justifyContent: 'space-between'}}>
                        <p className='profile-title'>User Profile</p>
                        <button onClick={() => {setUpdate(true)}} type='button' className='profile-edit-btn'><i id="profile-btn-icon" className="pi pi-user-edit"></i> Update Profile</button>
                    </div>
                    <div className='info-fields-section'>
                    <p id='user-profile-info'><span id='info-header'>First name<br /></span>{user?.firstName}</p>
                    <p id='user-profile-info'><span id='info-header'>Last name<br /></span>{user?.lastName}</p>
                    <p id='user-profile-info'><span id='info-header'>Username<br /> </span>{user?.username}</p>
                    <p id='user-profile-info'><span id='info-header'>Company name<br /> </span>{user?.client?.name}</p>
                    <p id='user-profile-info'><span id='info-header'>Account Created<br /> </span>{dateFormat(user?.createdAt, "dd/mm/yyyy HH:MM")}</p>
                    </div>
                </div>

                {/* ///Profile Edit form */}
                <div className={update ? 'create-modal-active' : 'create-modal-inactive'}>
                    <form id="form-modal" className='view-modal-content'>
                        <p className='modal-title'> Update Profile
                        <span id='close-button' class="material-symbols-outlined" onClick={() => handleCloseModal()}>close</span>
                        </p>
                        <div className="dialog-inputs">
                            <Input label="First Name *" name='firstName' type='text' defaultValue={user?.firstName} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.firstName}/>
                            <Input label="Last Name *" name='lastName' type='text' defaultValue={user?.lastName} handleChange={(e) => handleOnChange(e)} errorMessage={formErrors?.lastName}/>
                        </div>

                        <div className='dialog-footer'>
                            <SubmitButton pending={action.pending} handleOnSubmit={handleUpdateUser} />
                        </div>
                    </form>
                </div>

            </div>
            <Toaster position="top-right" expand={false} richColors/>

        </div>

    );
}

export default Profile