import React, { useState, useEffect } from 'react'
import "./webhooks.css"
import { DataGrid} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getWebhooks } from '../../apis/Webhooks';
import {getRegisteredNumbers} from '../../apis/RegisterdNumbers'
import { useDispatch } from 'react-redux';
import { Toaster, toast } from 'sonner'
import dateFormat from 'dateformat';
import Loading from '../ReUsedComponents/loadingScreen/Loading';


const Billing = () => {

    const dispatch = useDispatch()

    const [webHooks, setWebHooks] = useState([])
  
    const [pageSize, setPageSize] = useState(25);
    const [pageNo, setPageNo] = useState(1);

    const [whatsappNumber, setWhatsappNumber] = useState('')
    const [registeredWabaNumbers, setRegisteredWabaNumbers] = useState([])

        
    useEffect(() =>{ 
        getWebhooks(dispatch, pageSize, pageNo, whatsappNumber).then(resp => {
            if(resp.status === 200){
                setWebHooks(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

           getRegisteredNumbers(dispatch).then(resp => {
            if(resp.status === 200){
              setRegisteredWabaNumbers(resp.data?.data)
            }else{
              toast.error('Something went wrong. Re-load page!')
            }
           })

    }, [dispatch,pageNo, pageSize, whatsappNumber])

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
        //   backgroundColor: "#00B0E3",
          backgroundColor: theme.palette.common.black,
        //   boxShadow: theme.shadows[1],
          fontSize: 12,
        },
      }));

    const columns = [
        {field: 'endpoint', headerName: 'Endpoint',flex:1, minWidth: 300, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (props) => <LightTooltip title={props.row?.endpoint} placement="top-start" arrow> 
        <span>{props.row?.endpoint}</span></LightTooltip>},
        {field: 'eventType', headerName: 'Event Type',flex:1, minWidth: 180,headerClassName: 'custom-table-header',sortable: false,},
        {field: 'from', headerName: 'From',flex:1, minWidth: 130, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (props) => <span>{JSON.parse(props.row?.request).from}</span>},
        {field: 'text', headerName: 'Message', flex:1, minWidth: 180, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (props) => <span>{JSON.parse(props.row?.request).text}</span>},
        {field: 'profileName', headerName: 'Profile Name',flex:1, minWidth: 150, headerClassName: 'custom-table-header first-header',sortable: false,
        renderCell: (props) => <span>{JSON.parse(props.row?.request).profileName}</span>},
        {field: 'retries', headerName: 'Retries', flex:1, minWidth: 70, headerClassName: 'custom-table-header first-header',sortable: false,},
        { field: 'createdAt', headerName: 'Created At',flex:1, minWidth: 150, headerClassName: 'custom-table-header',sortable: false,
        renderCell: (props)=> <span>{dateFormat(props.row?.createdAt, "dd/mm/yyyy HH:MM")}</span>},
    ];

   

    return (
        <div class='table-page'>

            <div className='table-actions-sections'>
                <div className='table-page-title-section'>
                    <p className='table-page-title' style={{marginLeft: '11px'}}>Manage Callbacks </p>
                </div>
            </div>
            <div>

            <div className='table-toolbar'>
                    <div className='filter-messages-section'>
                        <label className='filter-label'>Filter by business number: </label>
                        <select type='text' className='filter-select-field' value={whatsappNumber} onChange={(e) => { setWhatsappNumber(e.target.value) }}>
                        <option id='option' value="" selected>All</option>
                        <option id='option' value={`whatsappNumber=+254110090747`}>Sandbox Number</option>
                        {registeredWabaNumbers.map((item, id) =>
                            <option id='option' key={id} style={{ fontSize: '14px' }} value={`whatsappNumber=${item.number}`}>{item.number}</option>
                        )}
                        </select>
                    </div>
             </div>

        
            <div>
           {webHooks.length === 0? <Loading /> : 
            <DataGrid rowHeight={40} rows={webHooks?.data} columns={columns} disableColumnMenu
                    className="datagrid-root"  getRowClassName="dashboard-transaction-rows"
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) =>setPageSize(newPageSize)}
                    onPageChange={(newPage) => setPageNo(newPage)}
                    rowsPerPageOptions={[25, 50, 100]}
                    rowCount={webHooks?.totalElements}
                    paginationMode="server"
                    pagination
                    componentsProps={{panel: { className: "custom-colum-panel",}, }}
                    sx={{border: 'none',}} 
                    autoHeight/>}
            </div>
          
            </div>

            <Toaster position="top-right" expand={false} richColors/>

        </div>
    )
}

export default Billing