import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import './orderstatuspage.css'
import {getOrderDetails} from '../../apis/Topup'
// import { getAccountBalance } from '../../apis/Topup'

const OrderStatusPage = () => {

  const [paymentDetails, setPaymentDetails] = useState()

  const dispatch = useDispatch()

    useEffect(() =>{ 
        getOrderDetails(dispatch).then(resp => {
          console.log(resp)
          setPaymentDetails(resp?.data) 
          })
     }, [dispatch])


  return (

<div>

  {paymentDetails?

  paymentDetails?.status_code === 1?

    <div class="container">
      <div class="printer-top"></div>
      <div class="paper-container">
          <div class="printer-bottom"></div>

          <div class="paper">
              <div class="main-contents">
                  <div class="success-payment-icon">&#10004;</div>
                  <p class="success-title">Completed Payment</p>
                  <p class="success-description">
                  Your {paymentDetails?.payment_method} payment of {paymentDetails?.currency} {paymentDetails?.amount} from account {paymentDetails?.payment_account
                        } was received successfuly. </p>
                  <div class="order-details">
                      <p class="order-number-label">Confirmation Code</p>
                      <p class="order-number">{paymentDetails?.confirmation_code}</p>

                      <div className='payment-action-btn'>
                        <a href='/app/topup' className="continue-payment-btn">Continue</a>
                      </div>
                  </div>
                
              </div>
              <div class="jagged-edge"></div>
          </div>
         </div>
      </div>

:

paymentDetails?.status_code === 0 || paymentDetails?.status_code === 2 || paymentDetails?.status_code === 3?

      <div class="container">
            <div class="printer-top"></div>
            <div class="paper-container">
                <div class="printer-bottom"></div>

                <div class="paper">
                    <div class="main-contents">
                      <img className="failed-payment-icon" src={process.env.PUBLIC_URL + "/images/credit-card-failed.png"} alt="company-logo" />
                      <p class="success-title">{paymentDetails?.payment_status_description} Payment</p>
                      <p class="success-description">{paymentDetails?.description}!!</p>
                        <div class="order-details">
                            <p class="order-number-label">Confirmation Code</p>
                            <p class="order-number">{paymentDetails?.confirmation_code}</p>
                            <div className='payment-action-btn'>
                              <a href='/app/topup' className="continue-payment-btn">Continue</a>
                            </div>
                        </div>
                      
                    </div>
                    <div class="jagged-edge"></div>
                </div>
            </div>
        </div>

:

""


:

<div className='payment-loader-section'>
  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  <p>Fetching Payment Details......</p>
</div>
   
}

</div>
     
  )
}

export default OrderStatusPage