import React, { useState, useEffect } from 'react';
import './getstarted.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { v4 as uuidv4 } from 'uuid';

const GetStarted = () => {

    const [uuid, setUuid] = useState('');

    useEffect(() => {
        const generatedUuid = uuidv4();
        setUuid(generatedUuid);
      }, []);

    const requestDataText = {
        message: "This is a test message.",
        messageId: "c8ef0df8-35d5-4541-9e02-0f1fd0a223d0",
        to: "{{PHONE_NUMBER}}",
        from: "{{SANDBOX_NUMBER}}" 
      };
  
      const responseData = {
        timestamp: "2023-08-06T01:27:56.825898971",
        data: {
          messageId: uuid,
          waId: "wamid.HBgMMjU0NzE3NzQ2NTY1FQIAEnRgSRjJGRkMzNkQ0QUVENTIxQ0NBAA==",
          status: "SENT",
          statusDesc: "Message sent successfully"
        },
        status: "success",
        message: "",
        errors: null
      };

      const callbackRequest = {
        messageId: "", 
        from: "", 
        profileName: "", 
        type: "TEXT", 
        text: "", 
        image: {
            caption: "",
            url: "" 
       },
        video: { 
            caption: "", 
            url: ""
        }, 
        document: {
            caption: "",
            url: "" 
         }
        }



  return (
    <div className='api-doc-section'>
        
        <div className='intro-sec'>
            <h3 className='intro-sec-header'>Getting Started</h3>
            <p className='intro-sec-par'>
            The WhatsApp API allows users to send different Message types to customers on Whatsapp.<br/>
            This document provides a comprehensive guide on how to interact with the API, including:
            <ul className='intro-sec-features'>
                <li id='intro-sec-feature-item'>Authentication</li>
                <li id='intro-sec-feature-item'>Request Format</li>
                <li id='intro-sec-feature-item'>Response Structure.</li>
            </ul>
            </p>
            <p className='intro-sec-par' >For testing purposes, <b>Lipachat</b> provides a test WhatsApp number that you can use to test your logic, 
                should you choose to do so, before onboarding your number.</p>
            <p className='intro-sec-par'>To use the sandbox number, <b>send your join code to +254110090747. </b>
                The 72-hour testing period will start as soon as you've sent the join code.
                 During this time, you can send free-form messages to your customer(s).</p>
            <p className='intro-sec-par'>After this period expires, you are free to initiate 
                another session - there is no limit on the number of sessions you can start.</p>
            <p className='intro-sec-par'>Details on how to obtain your join code will be shared separately.</p>
        </div>

        <div className='intro-sec'>
              <h3 className='intro-sec-header'>Authentication</h3>
              <p className='intro-sec-par'>
              To authenticate your requests, you must include an API key in the header of each API call. 
              The API key should be in the header.</p>
              <p style={{paddingTop: '10px', paddingBottom: '10px'}}> apiKey: <span style={{fontWeight: '600'}}>your-api-key-goes-here</span></p>
              <p className='intro-sec-par'>The Api Key will be shared separately.</p>
          </div>


        <div className='intro-sec'>
        <h3 className='intro-sec-header'>API requests</h3>
            <p className='request-type'>Message types can be either <b>TEXT, IMAGE, VIDEO, CONTACT, LOCATION, DOCUMENT, or INTERACTIVE.</b><br/>
              All API requests will have a base url  <b>https://gateway.lipachat.com</b><br/></p>
        </div>


        <div className='intro-sec'>
        <h4>Common Fields</h4>
        <table class="table">
            <tbody>
                <tr>
                    <td>
                    <strong>messageId</strong> <code>String</code> <code>Required</code> <br/>
                    <p id="parameter-desc">A unique identifier for the message. This will be used
                    to track the message status and for deduplication purposes.</p>
                    </td>
                </tr>

                <tr>
                    <td>
                    <strong>to</strong> <code>String</code> <code>Required</code> <br/>
                    <p id="parameter-desc">Comma Separated phone numbers</p>
                    </td>
                </tr>

                <tr>
                    <td>
                    <strong>from</strong> <code>String</code> <code>Required</code> <br/>
                    <p id="parameter-desc">Sandbox number +254110090747 or a valid WABA phone number.</p>
                    </td>
                </tr>
                
                    
            </tbody>
        </table>

        </div>


     <div className='intro-sec'>
        <h4>Response Body</h4>
        <p>Upon successful submission, the API will return a JSON object containing the following properties:</p>
   
            <table class="table">
                        <tbody>
                            <tr>
                                <td>
                                <strong>status</strong><br/>
                                <p id="parameter-desc">Status of the request: success or error</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                <strong>message</strong><br/>
                                <p id="parameter-desc">A detailed message of the status.</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                <strong>data.messageId</strong><br/>
                                <p id="parameter-desc">The unique identifier of the message, as provided in the request.</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                <strong>data.waId</strong><br/>
                                    <p id="parameter-desc">Whatsapp message id</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                <strong>data.status</strong><br/>
                                    <p id="parameter-desc">Whatsapp message status. SENT/FAILED.</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                <strong>timestamp</strong><br/>
                                    <p id="parameter-desc">The timestamp when the message was submitted.</p>
                                </td>
                            </tr>
                                
                        </tbody>
                </table>
        </div>

       
        <div className='intro-sec'>
            <div style={{margin: '10px 0px'}}>
            <h5>Sample Send Text API request</h5> 
            <SyntaxHighlighter className="custom-code-block" language="json" style={vscDarkPlus}>
            {JSON.stringify(requestDataText, null, 5)}
            </SyntaxHighlighter>
           </div>
        </div>


        <div className='intro-sec'> 
            <h4>Sample successful API call</h4>
            <p>On every API invocation, an API response is returned in JSON format. This API response JSON object response contains the following:</p>
            <SyntaxHighlighter className="custom-code-block" language="json" style={vscDarkPlus}>
            {JSON.stringify(responseData, null, 5)}
            </SyntaxHighlighter>
        </div>


        <div className='intro-sec'> 
            <h3 className='intro-sec-header'>Callbacks</h3>
            <p>Our system will send callbacks to an endpoint that you provide anytime your sandbox number 
                or live phone number receives a new message.The endpoint should be preferably https.</p>  
            <SyntaxHighlighter className="custom-code-block" language="json" style={vscDarkPlus}>
            {JSON.stringify(callbackRequest, null, 5)}
            </SyntaxHighlighter>

        </div>


    </div>
  )

}

export default GetStarted