import React, { useState, useEffect } from 'react';
import './getstarted/getstarted.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { v4 as uuidv4 } from 'uuid';

const APIStatusCodes = () => {

    const [uuid, setUuid] = useState('');

    useEffect(() => {
        const generatedUuid = uuidv4();
        setUuid(generatedUuid);
      }, []);

  
      const responseData200 = {
        timestamp: "2023-08-06T01:27:56.825898971",
        data: {
          messageId: uuid,
          waId: "wamid.HBgMMjU0NzE3NzQ2NTY1FQIAEnRgSRjJGRkMzNkQ0QUVENTIxQ0NBAA==",
          status: "SENT",
          statusDesc: "Message sent successfully"
        },
        status: "success",
        message: "",
        errors: null
      };

      const responseData401 = {
        timestamp: "2023-08-06T20:49:48.455464058", "data": null,
        status: "success",
        message: "Invalid credentials",
        errors: null 
      }
     
      const responseData400 = {
        timestamp: "2023-08-06T17:50:35.701+00:00",
        status: 400,
        error: "Bad Request",
        path: "/api/v1/whatsapp/message/text"
      };



  return (
    <div className='api-doc-section'>
        
        <div className='intro-sec'>
            <h3 className='intro-sec-header'>Status Codes</h3>
            <p className='intro-sec-par'>
            Our APIs are built to comply with HTTP Status codes. The following are error codes that will be returned whenever there are errors in a request<br/>
            </p>
        </div>


     <div className='intro-sec'>
        <h4>Common status codes</h4>
        <table class="table">
                        <tbody>
                            <tr>
                                <td>
                                <strong>200</strong><br/>
                                <p id="parameter-desc">Message sent successfully</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                <strong>400</strong><br/>
                                <p id="parameter-desc">Bad Request</p>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                <strong>401</strong><br/>
                                <p id="parameter-desc">Invalid credentials</p>
                                </td>
                            </tr>   
                        </tbody>
                </table>
        </div>


    
        <div className='intro-sec'>
            <h4>Http Status: 200</h4>
            <SyntaxHighlighter className="custom-code-block" language="json" style={vscDarkPlus}>
            {JSON.stringify(responseData200, null, 5)}
            </SyntaxHighlighter>
        </div>

        <div className='intro-sec'>
            <h4>Http Status: 401</h4> 
            <SyntaxHighlighter className="custom-code-block" language="json" style={vscDarkPlus}>
            {JSON.stringify(responseData401, null, 5)}
            </SyntaxHighlighter>
        </div>

        <div className='intro-sec'>
            <h4>Http Status: 400</h4>
            <SyntaxHighlighter className="custom-code-block" language="json" style={vscDarkPlus}>
            {JSON.stringify(responseData400, null, 5)}
            </SyntaxHighlighter>
        </div>

    </div>
  )

}

export default APIStatusCodes